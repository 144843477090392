.usp {
    background:$primary;
    padding-top: $section-spacer/2;
    padding-bottom: $section-spacer/2;
    color:$white;
    .container {
        display: flex;
        justify-content: space-evenly;
        @include media-breakpoint-down(md) {
            flex-direction: column;
        }

        .usp-box {
            display: flex;
            align-items: flex-start;
            gap: $spacer*1.5;
            max-width: $spacer*30;
            flex:1;
            @include media-breakpoint-down(md) {
                max-width: unset;
                &:not(:last-of-type) {
                    margin-bottom: $spacer;
                }
            }

            h3 {
                font-size: $font-size-base*1.625;
            }

            svg {
                position: relative;
                top: 3px;
                @include media-breakpoint-down(md) {
                    width: 100%;
                    max-width: $spacer*2;
                }
            }

            p {
                margin-bottom:0
            }
            
        }
    }

}
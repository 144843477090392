.product-line-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // .input-group.bootstrap-touchspin{
  //   max-width: 130px;
  // }
}
.product-line-grid {
  .product-line__title {
    margin-bottom: $spacer * 0.75;
    display: inline-block;
    color: $body-color;
  }
  .product-price {
    display: flex;
    align-items: center;
    .discount {
      font-size: $font-size-sm;
      line-height: 1;
    }
  }
  .product-discount {
    margin-right: $spacer/2;
  }
}
.product-line__img {
  margin-right: $spacer;
}
@include media-breakpoint-mobile {
  .product-line__img {
    width: 100px;
    height: auto;
  }
  .product-line-actions {
    justify-content: flex-end;
    margin-top: $spacer;
    .product-price {
      margin: 0 $spacer * 2;
    }
  }
}
@include media-breakpoint-mobile() {
  .product-line__img {
    width: $size-base;
  }
  .product-line-actions {
    .product-price {
      margin: 0 $spacer;
    }
  }
}

.cart__card-body {
  position: relative;
  .cart__card-loader {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
    display: none;
    background-color: rgba($white, 0.85);
    opacity: 0;
    @include transition($transition-fade);
    z-index: 3;
  }
  &.is--loading {
    .cart__card-loader {
      display: flex;
      opacity: 1;
    }
  }
}
.nav-tabs--center {
  justify-content: center;
}

.checkout-process {
  .card-header {
    padding: $spacer * 1.25;
  }
}

#order-detail-content .cart-line-product-quantity{
  min-width: $spacer*3;
}

#checkout,
#cart {
  h1 {
    color: $white;
  }
  #wrapper {
    margin-top: -$section-spacer * 1.5;
    padding-bottom: $section-spacer;
  }

  .card,
  .card-header,
  .card-header.bg-white {
    background: none;
  }

  .cart-container,
  .cart-grid-right {
    background: $white;
    border-radius: $border-radius-big;
    @include card-box-shadow;
  }

  .checkout-step:last-of-type .card-header {
    border: none;
  }

  .order-checkout,
  .checkout-process {
    border-radius: $border-radius-big;
    background: $white;
    @include card-box-shadow;
  }

  .btn:hover {
    box-shadow: none;
  }

  input.form-control,
  select {
    border-radius: $border-radius-lg;
    min-height: 50px;
  }

  #customer-form p {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: $spacer * 1.25;
    padding-left: $spacer * 1.25;
  }
}
@include media-breakpoint-mobile {
  .checkout {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0.4);
    a {
      width: 100%;
    }
  }
}
.product-line__body {
  padding-right: $spacer/4;
}

//order confirmation table
.total-value {
  font-weight: 700;
}
.table__title-head {
  margin-bottom: 0;
  font-size: $font-size-base;
}
/* CART */
.promo-code {
  margin-left: -$card-spacer-x;
  margin-right: -$card-spacer-x;
}
.promo-code-alert {
  display: none;
}
.cancel-promo {
  text-align: center;
}
.card-body .separator {
  margin-left: -$card-spacer-x;
  margin-right: -$card-spacer-x;
}

.cart-grid-right .cart-summary {
  position: sticky;
  top: $spacer * 10;
}

.cart-summary-line {
  display: flex;
  justify-content: space-between;
}
.cart-total {
  font-weight: bold;
}
.card-body--summary {
  .cart-total {
    @extend .total-value;
    .label {
      margin-bottom: 0;
    }
  }
  &.card-body {
    padding-bottom: 0;
  }
}
.media-list__item:not(:last-child) {
  margin-bottom: $spacer/2;
}

//cart summary
.link__showsummary {
  .material-icons {
    @extend .icon-collapse;
  }
  &[aria-expanded="true"] .material-icons {
    transform: rotateZ(180deg);
  }
}
.cart-item:not(:last-child) {
  border-bottom: 1px solid $border-color;
  margin-bottom: $spacer;
  padding-bottom: $spacer;
}
.product-line-info {
  margin-bottom: $spacer/2;
  &.product-price {
    .price {
      font-size: $h4-font-size;
    }
  }
}
.promo-code-button {
  text-align: center;
  .collapse-button[aria-expanded="true"] {
    display: none;
  }
}
.promo-highlighted {
  text-align: center;
  font-size: $font-size-sm;
  margin-top: $spacer;
  margin-bottom: $spacer/2;
}
.promo-discounts {
  font-weight: $font-weight-bold;
  text-align: center;
  color: $gray-600;
  .code {
    color: $tertiary;
  }
}
.form__add-voucher {
  margin-bottom: $spacer;
}
.promo-code {
  &__content {
    padding: $card-spacer-x;
    background-color: $promo-code-bg;
  }
  .collapse-button {
    text-align: center;
    display: block;
    color: $primary;
  }
}

/*spscheckout*/
h4.spsco-title {
  border: none;
}

#spstepcheckout {
  div#spsco_wrap {
    border-radius: $border-radius-lg;
    padding: $spacer * 1.25;
    .price {
      font-size: $font-size-base;
    }
  }

  ul.nav-tabs a.active{
    color: $primary;
  }

  .delivery_option_delay {
    font-size: $small-font-size;
  }

  .delivery_option_title, .payment_content {
    font-size: $small-font-size;
  }
}

#spsco_review {
  #doprava_zdarma_here_order {
    width: 100%;
    margin-top: $spacer*2;
}

}

@include media-breakpoint-down(lg) {
  #spstepcheckout a.remove-from-cart {
    position: relative;
    top: $spacer*2;
    right: $spacer;
}}
@include media-breakpoint-down(sm) {
  #spstepcheckout a.remove-from-cart {
    top: -$spacer*1.5;
    right: 0;
    float: right!important;}

}

section#content-hook_order_confirmation {
  margin-top: -$spacer*4.25;
}
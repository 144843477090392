
// Color system

$white:         #fff;
$primary:       #A50017;
$secondary:     #930317;
$tertiary:      #00000087;
$black:         #000;
$green:         #1ba71f;
$red:           #fe3737;
$border-color: #cecece78;

$info: lighten($primary, 25%);
$lighten: #FFECEF;

// Grayscale

$off-white:     #F1F1F1;

$gray-100:      #f7f7f7;
$gray-200:      #efefef;
$gray-300:      #ced4da;
$gray-400:      #a5aaae;
$gray-500:      #7c7f83;
$gray-600:      #525557;
$gray-700:      #424446;
$gray-800:      #313334;
$gray-900:      #171717;

$off-black:     #171717;

// Text colors

$text-gray:           $gray-600;
$text-black:          $off-black;


// Options

$enable-rounded:            true;
$enable-shadows:            true;

/* Bootstrap Reset */
address,
ol,
ul,
dl {
  margin-bottom: 0 !important;
}

// Links

$link-hover-decoration:     none;

// Fonts

$font-family-sans-serif:      'Rubik', sans-serif !default;
$headings-font-family:        $font-family-sans-serif;

$font-size-base:              1rem; // Assumes the browser default, typically `16px`

$h1-font-size:                $font-size-base * 1.5;
$h2-font-size:                $font-size-base * 1.375;
$h3-font-size:                $font-size-base * 1.25;
$h4-font-size:                $font-size-base * 1.125;
$h5-font-size:                $font-size-base;
$h6-font-size:                $font-size-base * 0.9375;

$weight-bold:                 900;
$weight-medium-bold:          600;
$weight-medium:               500;
$weight-regular:              400;
$weight-light:                300;

$display1-size:               $font-size-base * 4;
$display1-weight:             $weight-bold;
$display2-size:               $font-size-base * 3.375;
$display2-weight:             $weight-medium;
$display3-size:               $font-size-base * 2;
$display3-weight:             $weight-medium;
$display4-size:               $font-size-base * 1.75;
$display4-weight:             $weight-regular;
$display5-size:               $font-size-base * 1.25;
$display5-weight:             $weight-light;

$small-font-size:             $font-size-base * 0.875;
$tiny-font-size:              $font-size-base * 0.75;

/* Menu font settings */
$menu-font-size:              $font-size-base;
$menu-font-weight:            $weight-medium;

// Line height
$line-height-base:            1.125;

$big-line-height:             $line-height-base * 1.25;
$small-line-height:           $line-height-base * 0.875;
$tiny-line-height:            $line-height-base * 0.75;


// Icon sizing

$icon-xs-size:              .75rem;
$icon-small-size:           1rem;
$icon-normal-size:          1.25rem;
$icon-large-size:           1.5rem;
$icon-xl-size:              2rem;
$icon-2xl-size:             2.5rem;


// Components

$breadcrumb-bg:               transparent;

// Define common padding and border radius sizes and more.

$box-shadow:                  0px 0px 20px 0 rgba($black,.08);

/* Border radius */
$border-radius-lg: null;
@if $enable-rounded {
  $border-radius-lg:            .5rem;
} @else {
  $border-radius-lg:             0;
}

$border-radius-big: null;
@if $enable-rounded {
  $border-radius-big:            .75rem;
} @else {
  $border-radius-big:             0;
}

// Navs
$nav-tabs-border-width:             2px;
$nav-tabs-border-color:             $gray-200 !default;
$nav-tabs-link-hover-border-color:  transparent transparent $nav-tabs-border-color;
$nav-tabs-link-active-color:        $primary;
$nav-tabs-link-active-border-color: transparent transparent $primary;

// Pagination
$pagination-border-color:           $gray-400;
$pagination-disabled-border-color:  $gray-400;
$pagination-hover-border-color:     $gray-400;

// Modals
$modal-lg:                          990px;

//tables
$table-bg : $white;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  3xl: 2000px,
);

// Container
$container-max-widths: (
  // sm: 540px,
  // md: 720px,
  // lg: 960px,
  xl: 1161px,
  xxl: 1548px,
  3xl: 2066px,
);


/* Spacers */
$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3),
    6: ($spacer * 6)
  ),
  $spacers
);

$grid-gutter-width: 2.5rem;



// Components
//
// Define common padding and border radius sizes and more.

$box-shadow-sm:                 0 2px 16px rgba($black,.08);
$box-shadow-md:                 0 3px 30px rgba($black,.08);
$box-shadow:                    0px 0px 20px 0 rgba($black,.08);
$box-shadow-lg:                 0 6px 60px rgba($black,.09);
$box-shadow-lift-up:            0 36px 40px rgba($black,.08);


/* Border radius */

$border-radius-lg:            .5rem;

// Navs
$nav-tabs-border-width:             2px;
$nav-tabs-border-color:             $gray-200;
$nav-tabs-link-hover-border-color:  transparent transparent $nav-tabs-border-color;
$nav-tabs-link-active-color:        $primary;
$nav-tabs-link-active-border-color: transparent transparent $primary;

// Pagination
$pagination-border-color:           $gray-400;
$pagination-disabled-border-color:  $gray-400;
$pagination-hover-border-color:     $gray-400;

// Breadcrumbs

$breadcrumb-font-size:              $h4-font-size;

$breadcrumb-padding-y:              $spacer;
$breadcrumb-padding-x:              $spacer;
$breadcrumb-item-padding:           $spacer * .5;
$breadcrumb-margin-bottom:          $spacer;

$breadcrumb-bg:                     transparent;
$breadcrumb-divider-color:          $gray-200;
$breadcrumb-active-color:           $text-gray;
$breadcrumb-divider:                quote("―");

$breadcrumb-border-radius:          0;


// Modals
$modal-lg:                          990px;

//tables
$table-bg : $white;

$table-border-color:      $gray-200;
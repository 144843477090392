main {
    background:$main-bg-img;
    background-repeat:no-repeat;
    background-size: cover;
  }

.bold {
    font-weight: $weight-medium;
}

.custom-select {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: linear-gradient(45deg, transparent 50%, $primary 50%), linear-gradient(135deg, $primary 50%, transparent 50%), linear-gradient(to right, transparent, transparent);
    background-position: calc(100% - 20px) calc(1.2rem + 2px),calc(100% - 15px) calc(1.2rem + 2px),100% 0;
    background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
    background-repeat: no-repeat;
    box-shadow: none;
}

.custom-checkbox{
    i {
        display: none;
    }
}

.works_gdpr_checkbox .custom-checkbox {
    display: flex;
    input {
        margin-right: $spacer/4;
        margin-top: $spacer/4;
        min-width: 18px;
        }
    label {
        margin-bottom: 0;
    }

}

input[type="radio"]:checked {
    background-color: $primary;
   }
   input[type="checkbox"]::before {
    box-shadow: inset 1em 1em $primary;
  }
  input[type="checkbox"]:focus {
    outline-color:$primary;
  }

@media (max-width:$bp-desktop-lg) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
      max-width: calc( 100% - #{$spacer*2});
  }}

  #content-wrapper {
    width: 100%;
  }


  
// Button

.btn {
    padding: ($spacer * 1) ($spacer * 2.5);
    position: relative;
    overflow: hidden;
    z-index: 10;
    border: none;
    @include border-radius($btn-radius);
    font-size: $btn-font-size;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: $spacer * 0.25;
    @include transition-fast;
        &:hover {
        box-shadow: $box-shadow-lift-up;}
}

.btn-info, .btn-info:hover{
    background-color: $primary; 
    border-color:$primary  
}

.btn-primary {
    @include button-variant(transparent, transparent, transparent, transparent);
    @include btn-hover;
    svg {
        width: $btn-icon-size;
        height: $btn-icon-size;
        fill:$white;
        margin-right: $spacer/2
    }
    &.outlined {
        background: none;
        border: solid 2px $black;
        color:$black;
        font-weight: $weight-medium-bold;
        
        &.active, &.active:not(.disabled):not(.disabled) {
            color:$primary;
            box-shadow: none;
            border: solid 2px $primary;
        }

        &:hover,  &:hover:not(.disabled):not(.disabled) {
            color:$white;
            box-shadow: none;
            border: solid 2px $primary;
        }

        &::before {
            display:none
        }
    }
}

.exclusive_callforprice,.exclusive_callforprice:active {
    background: $primary;
    font-weight: $weight-medium-bold;
    &:hover {
        background:$secondary;
    }
}

.btn-outline-primary {
    @include button-outline-variant(
        transparent,
        transparent,
        transparent,
        transparent
    );
    color: $text-black;
    @include btn-hover-outline;
    svg {
        width: $btn-icon-size;
        height: $btn-icon-size;
        opacity: $btn-icon-opacity;
    }
}

.card {
    border: none;
}

.card-body {
    position: relative;
    @include media-breakpoint-mobile(up) {
        padding: ($spacer * 1.5) ($spacer * 1.5);
    }
    @include media-breakpoint-mobile() {
        padding: ($spacer * 1.5) ($spacer * 1.5);
    }
}



// Form

.form-control {
    &[type="email"],
    &[type="text"],
    &[type="password"] {
        min-height: $size-base;
        // padding: ($spacer * 1.75) ($spacer * 1)!important;
        @include border-radius($border-radius-lg);
        //border-color: lighten($primary, 25%);
        &:focus,
        &:active {
            border-color: $primary;
        }
    }
}
.custom-select {
    min-height: $size-base;
}

.custom-file {
    min-height: $size-base;
    .custom-file-label {
        height: 100%;
        &:after {
            display: flex;
            align-items: center;
            height: 100%;
        }
    }
}

.form-control[type=email], .form-control[type=password], .form-control[type=text], .form-control[type=tel] {
    min-height: $spacer*3.125;
}

.input-group-btn {
    button {
        height: 100%;
    }
}

form label {
    font-weight: $weight-regular
}

// .custom-control-label {
//     display: flex;
//     flex-wrap: wrap;
//     align-items: center;
// }
.custom-control-inline {
    padding-left: 0;
}

.custom-checkbox .custom-control-label:before {
    @include border-radius(50%);
}

.form-control.is-valid,
.was-validated .form-control:valid {
    background-position: right ($spacer * 1) center;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiMxMGM1NTAiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJmZWF0aGVyIGZlYXRoZXItY2hlY2siPjxwb2x5bGluZSBwb2ludHM9IjIwIDYgOSAxNyA0IDEyIj48L3BvbHlsaW5lPjwvc3ZnPg==");
}

.custom-control-label {
    color: $gray-500;
}

.card-header,
.page-header {
    background-color:  transparent;
}
// .card-footer,
// .page-footer {
//     background-color: $white;
// }

.input-group:not(.has-validation) > .input-group-btn:last-of-type button {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
.container {
    position: relative;
    z-index: 9999;
}
.breadcrumb-item + .breadcrumb-item:before {
    content: ">";
    letter-spacing: -1px;
}

.page-title-bar {
    background: $black url(/themes/eneko-theme/assets/img/eneko-slider.jpg) no-repeat center center;
    position:relative;
    padding-top: $section-spacer*1.5;
    padding-bottom: $section-spacer*2;
    
    .container {
        padding: 0;
    }
    &::before {
        content: "";
        background-color: transparentize($black, 0.4);
        width: 100%;
        height:100% ;
        position: absolute;
        left: 0;
        top:0;
    }


    .breadcrumb {
        color:$white;
        font-size: $font-size-base*0.875;
        padding: 0;

        svg {
            max-width: $font-size-base*0.75;
            margin-right: $font-size-base*0.75;
            position: relative;
            bottom: 2px;
        }

        li {
            &.active, &.active span {
                color:$white;
                font-size: $font-size-base*0.875;
                font-weight: $weight-medium-bold;
            }

            a {
                color:$white;
                font-size: $font-size-base*0.875;
            }
            
        }
    }
 

}

#authentication, #search {
    .page-title-bar {
        padding-top: 0; 
        @include media-breakpoint-down(md) {
            padding-bottom: $spacer*3; 
        }
    }
}

.modal-content {
     @include card-box-shadow;
}

.modal-header, .modal-footer {
    border: none;
}
.modal-backdrop, div.gomakoil_overlay {
    z-index: 99999;
    background-color: transparent;}

.modal, div.gomakoil_call_block {
    z-index: 9999999999
}

.crosssell_swiper {
    margin-top: $spacer;
    .product-miniature {
        width: 100%;
        height: 100%;
        }
    
    
}

#gomakoilFreeCall .input-group-addon{
    display: flex;
    align-items: center;
    padding-right: $spacer/2;
    color:$primary;    
}

div#gomakoilFreeCall .mpm-callforprice-focused-input .input-group-addon {
    background-color: transparent; 
    color: $secondary;
}

.header_call_back:after {
    display: none;
}

#header_call_back_background {
    background: none;
}

.gomakoil_call_block {
    background-color: $white;
}


#authentication {
    .page-header, .page-footer {
        background:none;
        border:none
    }
    #content-wrapper {
        margin-bottom: $spacer;
    }
}
@media (max-width:$bp-desktop-sm) {
.btn-light[data-action="show-password"] {
    display: none;
}

#authentication .input-group:not(.has-validation)>.form-control:not(:last-child) {
    border-top-right-radius:$btn-radius;
    border-bottom-right-radius:$btn-radius;
}

.page-content--authentication {
    padding: 0;
}
}
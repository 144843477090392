.block-categories {
  box-shadow:$card-box-shadow;
}

.category-filter {
  .btn {
    display: none;
    margin-bottom: $spacer*2;
    &:hover, &:focus, &:active, &:not(:disabled):not(.disabled):active {
      box-shadow: none;
    }
    
    @media (max-width:$bp-mobile-md) {
      display: inherit;
      max-width: max-content;
    }
    padding: 0;
    text-transform: uppercase;
    font-size:$font-size-base*1.25;
  }
  
}

.category-top-menu {
  margin-top: -$spacer*15;
  padding-bottom:  $section-spacer/2;
  &.collapse:not(.show) {display: inherit;}

  @media (max-width:$bp-mobile-md) {
    margin-top: 0;
    &:not(.show):not(.collapsing) {display: none;}
  }
  .category-title {
    font-size: $font-size-base*1.438;
    font-weight: $weight-medium-bold;
    margin-bottom: $spacer;
    color: $white;
  }

  // @media (max-width: $bp-mobile-xs){
  //   display: none;
  // }
}

.category-menu {
  display: flex;
  gap: $spacer/2;
  flex-wrap: wrap;
  @media (max-width: $bp-mobile-lg){
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }

  @media (max-width: $bp-mobile-md){
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: $bp-mobile-xs){
    grid-template-columns: repeat(2, 1fr);
  }

  
  .category-item {
    flex:1;
    border-bottom:none;
    
    @media (max-width: $bp-desktop-lg){
      max-width: $spacer*8.5;}

    @media (max-width: $bp-mobile-lg){
        max-width: unset;}

    @media (max-width: $bp-mobile-sm){
        padding: 0;}

      

    &:hover, &.active {
      a {
        background:$lighten;
        border:solid 2px $primary;
        @include btn-shadow-lighten;
        span {
          color:$primary;
        }
      }
    }
    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding:$spacer;
      border:$border-color 2px solid;
      background:$off-white;
      border-radius: $border-radius-big;
      min-height:$section-spacer*2;
      height: 100%;
      text-align: center;
      
      span {
        font-size: $font-size-base*0.813;
        color:$black;
        text-transform: uppercase;
        font-weight: $weight-medium;
        margin-top: $spacer/2;
        line-height: 1.5;
        margin: 0 $spacer*.85;
      }

      img {
        margin: 0 0 $spacer
      }
    }
  }
}

#category {

  #products {
    width:100%;
    
  }

  #wrapper {
    padding-top:$section-spacer;
    padding-bottom: $section-spacer; 
    @media(max-width:$bp-mobile-md) {
      padding-top:$section-spacer/2;
      padding-bottom: $section-spacer/2;
    }
  }



  h1{
    margin-bottom: $spacer*1.5;
  }

  .category-desc{
    margin-bottom: $spacer*2;
  }
  @media (max-width: $bp-mobile-md){
  .page-title-bar {
    padding-bottom: $spacer;
  }}

}



//block categories
.block-categories a{
  color:$body-color;
}
.category-sub__item{
  display: block;
  width: 100%;
}
.category-sub__item--0{
  border-bottom: 1px solid $border-color;
  padding: $spacer/4 0;
}
.category-sub__item:not(.category-sub__item--0):not(.category-sub__item--1) {
  padding-left: $spacer/4;
}
.block-categories .collapse-icons .remove {
  display: none;
}
.block-categories .collapse-icons[aria-expanded=true] {
  &.remove{
  display: block;
  }
  &.add{
    display: none;
  }
}


.featured-categories {
  .row {
    row-gap: $spacer * 2.5;
  }
  .category-item > .card {
    @include transition-fast;
    a {
      @include transition-fast;
    }
    &:hover {
      box-shadow: $box-shadow-lift-up;
      transform: translateY(-0.25rem);
      // a {
      //   color: $primary !important
      // }
    }
  }
  .category-thumbnail {
    img {
      width: 100%;
      height: 100%;
      max-height: 20rem;
      padding: 0 ($spacer * 1.5);
      object-fit: scale-down;
    }
  }
  .category-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .category-title {
      color: $text-black;
      font-size: $display4-size;
      font-weight: $weight-medium;
      a {
        color: inherit;
      }
    }
    .view-more a {
      font-size: $h3-font-size;
    }
  }
}
header {
  .header-nav__round-icon {
    @include border-radius(50%);
    @include flex-center;
    position: relative;
    @include transition(0.3s ease);
    z-index: 21;
  }

  .header__right-icon,
  .header-nav__round-icon a {
    color: $gray-900;
  }

  // .cart-icon .header__right-icon {
  //   color: $gray-900;
  // }

  .header-nav__round-icon.cart-icon {
    margin-right: $spacer/2;
  }
  
  .header-nav__round-icon a {
    width: 100%;
    height: 100%;
    pointer-events: all;
    display: flex;
    align-items: center;
    justify-content: center;
    @include border-radius(50%);
  }

  
  /* Blockcart */

  .blockcart__count {
    position: absolute;
    top: -$spacer/4;
    left: $spacer/4;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(25%, -25%);
    border:solid 2px $gray-900;
    background: $primary;
    color: $white;
    @include border-radius(50%);
    @include transition(0.15s ease);

    &:empty {
      opacity: 1;
    }
  }

  .inactive .blockcart__count {
    opacity: 1;
  }

  .inactive .shopping-cart a {
    pointer-events: none;
  }

  @include media-breakpoint-tablet {
    .header-nav__round-icon {
      background-color: transparent;
    }
  }
}

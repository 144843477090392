$search-btn-size: $spacer * 2.5;
$search-box-padding:    $spacer * .3125;

.search-widget {
  height: $search-btn-size + ($search-box-padding * 2);
  width: $search-btn-size + ($search-box-padding * 2);
  position: relative;
  z-index: 99;
  button {
    position: absolute;
    width: $spacer*3;
    height: 100%;
    top: 0;
    right: 0;
    background: none;
    color: $white;
    border-radius: 0 $border-radius-lg $border-radius-lg 0;
    padding: 0;
  }
  input[type="text"] {
    padding: $spacer/1.25;
    border-radius: $border-radius-lg;
    border: none;
    font-size: $font-size-base;
    height: 50px; 
    outline: none
  }
}
.search-widget__group {
  position: relative;
}
.search-widget__input-right {
  padding-right: $spacer * 3.125;
}
.search-widget__btn {
  position: absolute;
  right: 0;
  top: 0;
}

.search-btn {
  width: $search-btn-size;
  height: $search-btn-size;
  @include border-radius(50%);
  display: flex;
  align-items: center;
  justify-content: center;
  position:relative;
  @include transition-fast;
  border: none;
  background:$gray-100;
  &[disabled] {
    pointer-events: none;
  }
}

.search-box {
  position: absolute;
  right: 0;
  @include border-radius(3.125rem);
  padding: $search-box-padding;
  display: flex;
  background: $gray-100;
}
/* input */
.search-input {
  outline: none;
  border: none;
  background: none;
  width: 0;
  padding: 0;
  float: left;

  opacity: 0;
  @include transition-fast;

  font-size: $font-size-base;
  line-height: 2.5rem;
  color:$black;
}
.search-input::placeholder {
  color: $text-gray;
}
@include media-breakpoint-desktop {
  .search-input:focus,
  .search-input:not(:placeholder-shown),
  .search-box:hover > .search-input {
    width: 15rem;
    padding: 0 $spacer;
  }
}

.search-box:hover > .search-input,
.search-input:focus,
.search-input:not(:placeholder-shown){
  opacity: 1;
}

.search-btn:not([disabled]):hover {
  background-color: $white;
}

@include media-breakpoint-mobile {
  .search-input:focus,
  .search-input:not(:placeholder-shown),
  .search-box:hover > .search-input 
  {
    width: auto;
    padding: 0 $spacer;
  }

  .search-input button {
    @include transition-fast;
  }

  .search-input:focus ~ button,
  .search-input:not(:placeholder-shown) ~ button,
  .search-box:hover > .search-input ~ button {
    max-width: 0;
    opacity: 0;
    padding: 0;
  }
}

header {
  background-color: $header-bg;
  position: sticky;
  top: -1px;
  z-index: 21;
  flex-wrap: wrap;
  padding: $spacer*1.25 $spacer*2;
  @include media-breakpoint-down(sm) {
    padding: $spacer;
  }
  @include transition-fast;
  z-index:9999999999


}


  /* Header Top Nav */
  .header-top__content {
    height: $header-height;
    align-items: center;
  }

  .header-nav__left {
    span,
    a:not(.dropdown-item) {
      color: $text-gray;
    }
  }

  .header__right,
  .header-nav__right,
  .footer-bar__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include media-breakpoint-desktop {
      position: relative;
    }
  }

  .header__right > div:not(#search_widget):not(.megamenu-mobile-toggle){
      margin-right: $spacer*2;
      min-width: max-content;
      @include media-breakpoint-down(lg) {
        margin-right: $spacer;
      }

      @include media-breakpoint-down(sm) {
        margin-right: $spacer/2;
      }
  }

  .header-nav {
    background-color: $header-nav-bg-color;
    color: $header-nav-color;
    padding-top: $header-nav-padding-y;
    padding-bottom: $header-nav-padding-y;
    a:not(.dropdown-item),
    .btn {
      color: $header-nav-color;
    }
    .header-nav__wrapper {
      min-height: $spacer * 3;
    }
  }

  .header-nav__right{
    background-color: $header-nav-right-bg-color;
    @include transition-fast;
    svg {
      @include transition(inherit);
    }
    a {
      @include transition(inherit);
    }
    &:hover {
      background-color: darken($header-nav-right-bg-color, 5%);
      a {
        transform: translateY(-($spacer * 0.0625));
        color: $primary !important;
        svg {
          color: inherit;
        }
      }
    }
  }

  .header-nav__right a {
    font-weight: $font-weight-bolder;
    letter-spacing: 1px;
  }

  .header__logo--checkout {
    max-width: 200px;
  }
  .header-nav--checkout {
    padding-top: $spacer;
    padding-bottom: $spacer;
  }
  
    .megamenu-mobile-toggle {
      cursor: pointer;
      z-index: 999999;
      display: none;
      margin-right: 0;
      @media (max-width:$bp-desktop-lg) {
        display: inherit;
      }
      svg {
        line {
          transform-origin: center;
          color: $primary;
          @include transition-fast;
        }
        &.megamenu-open {
          line {
            &:nth-of-type(1) {
              opacity: 0;
            }
            &:nth-of-type(2) {
              transform: rotate(45deg) translateY(25%);
            }
            &:nth-of-type(3) {
              transform: rotate(-45deg) translateY(-25%);
            }
          }
        }
      }
      &:hover,
      &:active,
      &:focus {
        svg {
          color: $primary;
        }
      }
    }

header {
  background:transparentize($white, .01);
  border-bottom: $border-color solid 1px;
  @include blured;
  position:fixed;
  width:100%;
  
  svg#logo {
    width: 100%;
    fill: $primary;
  }

  .header__left, .header__right{
    display: flex;
    @media (max-width:$bp-mobile-lg) {
      .user-info {
        display: none;
      }
    }
  

    @media (max-width:$bp-mobile-xs) {
      width: 100%;
      justify-content: space-between;
      
      &.header-top__col {
        font-size: 0;
        justify-content: flex-end;
        margin-top: $spacer*.75;
        margin-bottom: $spacer/2;
        max-width: max-content;}
    }
   }

   .header-nav__round-icon  svg:not(#close-search):not(.megamenu-mobile-toggle) {
     fill: $primary;
     color: $primary;
     width: 100%;
     height: 100%;
     max-height: $spacer*1.25;
     overflow: visible;
     margin-right: $spacer;
     max-width: $spacer*1.25;
     @include media-breakpoint-down(sm) {
      margin-right: 0;
     }
   }
  #close-search {
    cursor: pointer;
    fill:$primary
  }

  a.header__logo {
    max-width:$spacer*12;
    @include media-breakpoint-down(md) {
      max-width:$spacer*10;
     }
  }

  .header_search {
    color: $gray-900;
    position: relative;
    top: 0;
    height: 50px;
    display: flex;
    align-items: center;
    cursor:pointer;
    @include transition-fast;
    &.hidden {
      visibility:hidden
    }

    .material-icons {
      font-size:$font-size-base*1.75;
      margin-right: $spacer*0.5;
      color:$primary;
      @include media-breakpoint-down(sm) {
        margin-right:0
      }
    }

  }

  #search_widget {
    position: relative;
    right: $spacer*1.5;
    max-width: 0;
    @include transition-fast;
    @include media-breakpoint-down(md) {
      position: absolute;
      right: 0;
      top:$spacer*5;
      z-index: 999999999;
    }
      
  }

  .searchOpen {
    .header_search {
      opacity: 0;
    }
    #search_widget  {
    max-width: 28rem;
    position:absolute
    }
    
  } 
}
  
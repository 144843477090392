.products {
    align-items: stretch;
}



.card-product {
    height: 100%;
    position: relative;
    background-color: $product-miniature-bg-color;
    border-width: $product-miniature-border-width;
    border-color: $product-miniature-border-color;
    .card-body {
        justify-content: space-between;
        flex-direction:column
    }
    .discount {
        display: none;
    }
    .product-buttons {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;
        padding-top: inherit;
        padding-bottom: $spacer/4;
        @include media-breakpoint-mobile {
            padding-bottom: 0;
        }

    }
}

.card-img-top {
    overflow: hidden;
    .product-thumbnail img {
        width: 100%;
    }
}

.product-flags {
    position: absolute;
    top: $spacer;
    right:$spacer;
    margin-bottom: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.product-flag {
    top: 0;
    margin-top: $spacer/2;
    padding: $spacer*0.15 $spacer;
    color: $white;
    background: $primary;
    font-weight: $weight-light;
    font-size: $font-size-base*0.75;
    min-width: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius-lg;
    text-transform: lowercase;

    &.discount-product {
        background: $primary;
        text-transform: lowercase;
    }
}
.color {
    width: 1.25rem;
    height: 1.25rem;
    display: inline-block;
    border: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    background-size: contain;
}

.product-title {
    color: $product-miniature-name-color;
    font-size: $product-miniature-name-font-size;
    text-decoration: none;
    text-align: left;
    font-weight: $product-miniature-name-font-weight;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    & a {
        color: $product-miniature-name-color;
        &:hover {
            color: $product-miniature-name-hover-color;
        }
    }
    // .card-product:hover & {
    //     color: $product-miniature-name-hover-color;
    //     & a {
    //         color: $product-miniature-name-hover-color;
    //     }
    // }
}

/* PRODUCT PAGE */

#product {

    #content-wrapper {
        padding-bottom: $section-spacer;
      }

    .images-container{
        margin-top: -$section-spacer*1.5;
        .rc, img {
            border-radius: $border-radius-big;
        }

        .rc {
            border:solid 1px $off-white
        }

        .btn:hover {
            box-shadow: none;
        }


    }

    .product-details {
        padding-top: $section-spacer;
    }

    h1 {
        font-weight: $weight-medium-bold;
        font-size: $font-size-base*2.5;
    }

    #content {
        position:relative
    }
}

.product-footer {
    background: $gray-200;
    border-radius: $border-radius-big;
    padding: $spacer*2;
}

.product-prices {
    display: flex;
    align-items: flex-end;
    height: 100%;
}

.product-footer-usps {
    color: $green;
    font-weight: $weight-medium;
    display: flex;
    gap: $spacer;
    padding-bottom: $spacer * 1.25;
    margin-bottom: $spacer * 1.25;
    border-bottom: 1px solid $gray-300;
    font-size:$font-size-base*0.8;
    // flex-wrap: wrap;


    > span {
        flex-basis: calc(100% / 3);
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: $spacer * 0.75; 
        @media(max-width:$bp-desktop-sm) {
            flex-direction: column;
        }
        @media(max-width:$bp-mobile-xs) {
            flex-direction: row;
            min-width: max-content;
            &:nth-child(3), svg {
                display: none;
            }
            
        }

    }

    svg {
        max-width: $spacer*1.15;
        max-height: $spacer*1.15;
        width: 100%;
        path, rect {
            fill:$success
        }
    }

    .outofstock, .long-delivery, .not_on_stock, .almostoutofstock{
        color:$primary;
        svg * {
            fill: $primary;
        }
    }

    .almostoutofstock {
        svg {
            max-width: $spacer;
        }
    }

    
}

.product-add-to-cart-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    gap: $spacer * 1.5;
}

.product-add-to-cart {
    display:flex;
    .product-quantity {
        margin-top: -($spacer);
    }
    .qty {
        margin-top: $spacer;
        .input-group {
            border-radius: $border-radius-big;
            box-shadow: none;
        }

        #quantity_wanted {
            height: 60px;
            min-width: $spacer*6;
            padding-right: $spacer*3;
        }

        .input-group-btn {
            position: absolute;
            height: 50%;
            width: $spacer*2;
            right: 0;
            margin: 0;
            padding: 0;
            border-left: 1px solid $gray-200;
            justify-content: center;
            &::before, &::after {
                display:none
            }
            .btn {
                padding:0;
                width:100%;
                box-shadow:none;
                &:hover {
                    color:$primary
                }
            }
            &.input-group-append {
                top:0
            }
            &.input-group-prepend {
                bottom:0
            }
        }

        .js-touchspin {
            font-size:0;
            &::before {
                content: "";
                width: 9px;
                height: 9px;
                position: absolute;
                border-left: solid 2px $gray-400;
                border-top: solid 2px $gray-400;
                transform: rotate(45deg);
                top: 14px;
            }

            &.bootstrap-touchspin-down::before {
                    transform: rotate(225deg); 
                    top:7px
            }
        }
    }
    .add {
        margin-top: $spacer;
    }
    .btn-primary {
        height:60px;
        @include btn-shadow-lighten;
    }
}

.products-imagescover {
    box-shadow: $card-box-shadow;
}
.product-img:not(:first-child) .rc {
    display: none;
}
.product-thumbs {
   // width: $product-thumb-wrap-width;
    width:100%;
    margin: 0 auto;
    display:flex;
    &[data-count="2"] {
        width: $product-thumb-wrap-width-2;
    }
}
.product-thumb {
    margin: $product-thumb-margin;
    //padding:5px;
    //height:  auto;
    width: $product-thumb-width;
    height: $product-thumb-height;
    border: 2px solid transparent;
}
.btn-zoom {
    position: absolute;
    right: $spacer;
    bottom: $spacer;
    cursor: pointer;
    display: none;
    padding: 0;
    line-height: 1;
    & .material-icons {
        font-size: 3 * $font-size-base;
    }
}
.images-container:hover .btn-zoom {
    display: block;
}
.label-color {
    line-height: 1;
    margin-right: $spacer;
}

.product-variants {
    border-top: solid 1px $off-white;
    padding-top: $spacer;
}

.product-variants-item .color {
    width: 4rem;
    height: 4rem;
    border: 2px solid transparent;
    border-radius: $border-radius-big;
    @include transition-fast;

    img {
        max-width: 100%;
        border-radius: $border-radius-big;
        border: solid 2px transparent
    }

    .color-name {
        text-align: center;
        margin-top: $spacer/2;
        display: block;
        font-size: $font-size-base*0.875;
        font-weight: $weight-light;

    }
}

.product-colors {
    margin-bottom: $spacer*2;
}

.product-variants-item label {
    &:hover .color img, &.checked .color img {
        border: solid 2px $primary
    } 

    &:hover .color-name, &.checked .color-name {
        color: $primary;
        font-weight: $weight-medium-bold;
    } 
}


.tax-shipping-delivery-label {
    font-size: $small-font-size;
    color: $gray-400;
    font-weight: $weight-medium-bold;
}

.product-information {
    margin-top: $spacer;
}

//product prices
.product__product-price {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}
.product__product-price .current-price {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    gap: $spacer * 0.5;
}
.price {
    color: $product-price-current-color;
    font-size: $product-price-current-font-size;
    font-weight: $product-price-current-font-weight;
    font-family: $product-price-current-font-family;
    &.current-price-discount {
        font-weight: $product-price-discount-font-weight;
        font-family: $product-price-discount-font-family;
    }
}
.regular-price {
    color: $product-price-regular-color;
    font-size: $product-price-regular-font-size;
    font-weight: $product-price-regular-font-weight;
    font-family: $product-price-regular-font-family;
    text-decoration: line-through;
}
.discount {
    text-transform: uppercase;
    color: $white;
    background-color: $product-price-discount-color;
    font-weight: 600;
    display: inline-block;
    padding: 0.25 * $spacer;
}
.discount.discount-percentage {
    color: $primary;
    background:none;
    font-size: $product-price-regular-font-size;
}

//product add to cart
.btn-add-to-cart {
    font-weight: 600;
    white-space: nowrap;
}
.attributes {
    gap: $spacer * 0.5;
}
//product tabs
.product-tabs {
    padding:0;
    background:none;
    .nav-tabs {
        border: 0px;
        font-size: $font-size-base * 0.9;
        li {
            min-width: max-content;
            margin-bottom: $spacer/2;
        }
    }

    .nav-item:not(:last-of-type) {
        margin-right: $spacer;
    }
}
.card--reassurance {
    box-shadow: $card-box-shadow;
}

// modal add to cart
.cart-content {
    padding: $spacer;
    background: theme-color("light");
}

.checkout-summary {
    position: sticky;
    top: 10rem;
}

//product pack
.pack-product-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 $spacer;
    font-size: $font-size-sm;
}
.mask {
    width: 55px;
}
.thumb-mask {
    padding: $spacer 0;
}
.pack-product__img {
    border: 1px solid $border-color;
}
.pack-product-name {
    flex-grow: 1;
    padding: 0 $spacer * 2;
}
.pack-product-quantity {
    border-left: 1px solid $border-color;
}
.pack-product-price {
    padding-right: $spacer/2;
}
.pack-product-quantity {
    display: flex;
    align-items: center;
    align-self: stretch;
    display: flex;
    align-items: center;
    align-self: stretch;
    padding-left: $spacer;
}

//modal cart
.modal-cart__image {
    margin-right: $spacer;
    max-width: 200px;

    height: auto;
}
.modal-cart__name {
    color: $primary;
    font-weight: $font-weight-bold;
}
.product-total {
    font-weight: $font-weight-bold;
}
.cart-content {
    > p:not(:last-child) {
        margin-bottom: $spacer/2;
    }
}

.cart-modal-summary-column {
    display: flex;
    flex-direction: column-reverse;
    #doprava_zdarma_here_external div#roundedbox, #doprava_zdarma_here_popup div#freeshipping_progress {
        margin-top: 0;
    }

    #doprava_zdarma_here_popup {
        padding-bottom: 0;
        }
    
}

#doprava_zdarma_here_order div#roundedbox span.middle{
    font-size: $font-size-base*.875;    
}


@include media-breakpoint-mobile {
    .modal-cart__image {
        max-width: 120px;
    }
    .blockcart-modal {
        .divide-right {
            display: flex;
            justify-content: center;
        }
        .modal-footer {
            position: fixed;
            bottom: 0;
            background: $white;
            justify-content: center;
            left: 0;
            width: 100%;
            box-shadow: 0px -0.25rem 0.5rem rgba($black, 0.15);
        }
        .modal-body {
            margin-bottom: ($btn-padding-y * 2) +
                ($btn-font-size * $btn-line-height) + $modal-inner-padding * 2;
        }
    }
}
@include media-breakpoint-down(xs) {
    .modal-cart__image {
        max-width: 70px;
    }
}
//spinner add to cart
.btn-add-to-cart {
    .btn-add-to-cart__spinner {
        width: $spinner-width-addtocart;
        height: $spinner-height-addtocart;
        border-width: $spinner-borderwidth-addtocart;
        display: none;
    }
    &.is--loading {
        .btn-add-to-cart__spinner {
            display: inline-block;
        }
        .btn-add-to-cart__icon {
            display: none;
        }
    }
}

.products-selection {
    justify-content: space-between;
    align-items: center;
}
.pack-miniature-item {
    margin-bottom: $spacer / 2;
}
.ratio1_1 {
    display: block;
}
.pack-product-name {
    margin: 0 $spacer * 1.5;
    flex-grow: 1;
}
.product__card-img {
    position: relative;
}
.product__card-desc {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.quantity__label {
    margin-bottom: 0;
}
.product-add-to-cart {
    .product-quantity {
        gap: $spacer;
    }
}
.product-customization {
    margin-bottom: $spacer * 1.5;
}
.product-discounts {
    margin-bottom: $spacer * 1.5;
}
.product__quantity {
    margin-bottom: 0;
    color: $gray-400;
}
.sort-by-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.total__item-show {
    text-align: center;
    font-size: $font-size-sm;
    margin-top: $spacer;
}
.variant-links {
    display: flex;
    justify-content: center;
}
.product__download {
    text-transform: uppercase;
}
.card--address {
    height: 100%;
    text-align: center;
    justify-content: center;
}
.invoice__address {
    margin-top: $spacer;
}
.invoice-address {
    height: 100%;
    text-align: center;
    justify-content: center;
}
.pagination-summary {
    color: $gray-400;
}

@include media-breakpoint-mobile {
    #_mobile_search_filters .card-block {
        padding: 0 !important;
        box-shadow: none !important;
    }
}


.product-accessories {
    .container.narrow {
        @include tabbed;
  
      }
    padding-bottom: $section-spacer;
    .section-title {
        margin-bottom: $spacer*4;
    }
     
}

/* Toto zboží si právě prohlíží */

.r_w.r_w_exists {
    position: fixed;
    left:$spacer*3;
    bottom:$spacer*3;
    z-index: 9999999999999;
    font-size: $font-size-base*.85;
    padding: $spacer*1.5;
    background: $white;
    border-radius: $border-radius-big;
    color: $gray-600;
    @include card-box-shadow;
    .watchers {
        font-weight: $weight-medium;
    }

    svg {
        max-width: $spacer*1.25;
        height: auto;
        margin-right: $spacer*0.3;
        fill: $gray-400;
    }
    
}
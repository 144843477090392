@import "simplebar";
@include media-breakpoint-up(md) {
  .blockcart:hover,
  .blockcart.hovered {
    .cart-preview {
      transform: translate(0, -$spacer) rotateX(0);
      transform-style: preserve-3d;
      opacity: 1;
      pointer-events: all;
    }
  }
}

.cart-preview {
  max-width: 100vw;
  width: $cart-preview-width;
  padding-top: $spacer * 2;
  pointer-events: none;
  position: absolute;
  top: $spacer * 2;
  right: 0;
  z-index: 99999;

  opacity: 0;
  transform-origin: bottom center;
  transform: translate(0, -$spacer/2) rotateX(-15deg);
  transition: 0.3s ease;

  .btn-primary {
    &:before {
      background: $primary;
    }

    &:after {
      background: $secondary;
    }
  }

  .cart-content {
    position: relative;
    min-height: $cart-preview-item-min-height;
    background: $white;
    box-shadow: $box-shadow-md;
    padding: $cart-preview-padding-y $cart-preview-padding-x;
  }

  .content-list {
    padding: 0 $spacer;
    margin: $spacer 0;
    max-height: Min(
      (#{$cart-preview-item-min-height * $cart-preview-items-visible}),
      33vh
    );
    position: relative;
    overflow-x: hidden;
    .simplebar-scrollbar::before {
      background-color: $gray-500;
    }
  }

  .cart-empty {
    opacity: 0;
    animation: fadeInUp 0.3s ease 0s forwards 1;
    min-height: $cart-preview-item-min-height;

    svg {
      margin-right: $spacer;
    }
  }

  .simplebar-content {
    overflow-x: hidden;
  }

  .preview-item {
    padding-bottom: $spacer * 1.5;
    overflow: hidden;

    opacity: 1;
    transform: scale(1);
    transition: 0.3s ease;
    max-height: 30rem;
    .input-group {
      box-shadow: none;
      border: none;
      width: auto;
      .form-control {
        max-width: 2.5rem;
        height: auto;
        padding: 0 $spacer/2;
        font-size: $tiny-font-size;
        min-height: unset;
      }
      .input-group-btn {
        position: relative;
        overflow: hidden;
        &:before,
        &:after {
          content: "";
          width: 100%;
          position: absolute;
          z-index: 0;
          bottom: 0;
          transition: 0.15s ease-in-out;
        }
        &:before {
          height: 100%;
          transform-origin: right;
        }
        &:after {
          height: 100%;
          transform: scaleX(0);
        }
        &:first-of-type:after {
          transform-origin: left;
        }
        &:last-of-type:after {
          transform-origin: right;
        }
        &:hover:after,
        &:focus:after,
        &:active:after {
          transform: scaleX(1);
        }
        button {
          transition: 0.15s ease;
          padding: 0 $spacer/2;
          background-color: transparent;
          // &:hover, &:active, &:focus {
          //   color: $white;
          // }
        }
      }
    }
    .product-image img {
      object-fit: cover;
    }

    .product-name {
      color: $text-black;
      font-size: $small-font-size;
      font-weight: $weight-regular;
      line-height: $line-height-base;
      margin-bottom: 0;
    }

    .product-price {
      font-size: $small-font-size;
      font-weight: $cart-preview-product-price-font-weight;
    }

    .product-quantity {
      font-size: $tiny-font-size;
      color: $gray-500;
    }

    .product-attributes {
      font-size: $tiny-font-size;
      color: $gray-500;
      strong {
        color: $gray-700;
      }
    }
  }

  .cart-subtotals,
  .cart-totals {
    font-size: $small-font-size;
    color: $text-gray;
  }

  .remove-from-cart {
    svg {
      color: $primary;
      transition: 0ms;
      transform: scale(1);
    }
    &:hover {
      svg {
        color: $white;
        fill: $secondary;
        transform: scale(1.1);
      }
    }
  }

  .cart-footer {
    padding: 0 $spacer;
    min-height: $cart-preview-item-min-height;
    overflow: hidden;
  }

  .cart-spinner {
    position: absolute;
    pointer-events: none;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    &.removed {
      opacity: 0;
      transform: scale(0.75);
    }
  }

  .preview-item,
  .cart-footer {
    &.removed {
      padding-bottom: 0;
      min-height: 0;
      opacity: 0;
      transform: scale(0.75);
      pointer-events: none;
      max-height: 0;
    }
  }
}

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 2rem, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@include media-breakpoint-mobile {
  .cart-preview {
    transform: translate(0, -$spacer/2) rotateX(0);
    padding-top: $spacer;
    .cart-content {
      padding: 0;
    }
    .cart-footer {
      padding-bottom: $spacer;
    }
  }

  .blockcart:hover,
  .blockcart.hovered {
    .cart-preview {
      transform: translate(0) rotateX(0);
    }
  }
}

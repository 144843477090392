.footer-before {
    padding-top: $section-spacer;
    padding-bottom: $section-spacer;
    background: url(/themes/eneko-theme/assets/img/footer-bg.jpg);
    position:relative;
    overflow:hidden;
    &::before {
      content: "";
      background: url(/themes/eneko-theme/assets/img/plamen.png) no-repeat;
      width: 45%;
      height: 44rem;
      position: absolute;
      background-size: contain;
      bottom: -17rem;
      left: -10rem;
    }

    .footer-before-about {
      display: flex;
      align-items: center;
      margin-bottom:$spacer*3;
      color: $white;
      gap: $spacer*3;
      font-weight: $weight-medium-bold;

      @media (max-width:$bp-mobile-sm) {
        flex-direction: column;
      }
      >div, >video, >iframe {
        flex:1
      }
      iframe {
        border-radius: $border-radius-big;
        max-width:$spacer*28;
        overflow: hidden;
        @media (max-width:$bp-mobile-sm) { 
          max-width: unset;
        }
      }

      h2  {
        font-size: $font-size-base*2;
        font-weight: $weight-medium-bold}
    }

    .block_newsletter {
      background: transparentize($white, 0.7);
      border-radius: $border-radius-big; 
      padding:$spacer*2.75;
      display: flex;
      align-items: center;
      justify-content:space-between;
      @include media-breakpoint-down(sm) {
        padding:$spacer
      }

      .input-group {
        gap:$spacer;
      }
      

      @include blured;
        h2, p {
        text-align: left;
        color: $white;
        }

        h2 {
          font-size: $font-size-base*1.625;
        }

        p {
          font-weight: $weight-light;
        }

        input[type="email"][name="email"] {
          background: none;
          border: solid 2px $white;
          color:$white;
          border-radius: $border-radius-big;
          min-width: max-content;
          max-width: $spacer*25;
          min-height: $spacer*3.438;
          padding: $spacer $spacer*2;
          &::placeholder {
            color:transparentize($white, 0.3);

          }
          
        }

        button.btn-primary {
          border-radius: $border-radius-big;
          text-transform: none;
          font-weight: $weight-medium;
          padding: $spacer $spacer*3.5 $spacer $spacer*2.5;
          @include slider-btn-shadow;
          svg {
            margin-right:$spacer/2
          }
        }
      

        #obalka {
          max-width: $spacer*20;
          margin-top: -$spacer*7;
          @media (max-width:$bp-mobile-xs) {
            display: none;
          }
        }
      }


    
}

.footer-contact-info {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: $spacer*3;
  background-color: $primary;
  border-radius: $border-radius-big;
  padding:$spacer*2 $spacer*3;
  position:relative;
  top:-$spacer*3;
  @include card-box-shadow;
  @media (max-width:$bp-mobile-md) {
      gap:$spacer
  }
  a {
    color:$white;
    flex:auto;
    &:last-child {
      text-align: right;
    }

    @media (max-width:$bp-mobile-lg) {
      width: 33.33%;
      &, &:last-child {
        text-align: center;
      }
    }

    @media (max-width:$bp-mobile-xs) {
      width: 100%;
      &, &:last-child {
        text-align: left;
      }
    }

    @include transition-fast;
      &:hover {
      box-shadow: $box-shadow-lift-up;
      text-decoration: underline;}
  }

  svg {
    fill: $white;
    max-height: $spacer*1.15;
    max-width: $spacer*1.15;
    position: relative;
    bottom: 2px;
    margin-right: $spacer/4;
  }
}

.footer-menu-wrapper {
  border-bottom: 1px solid $border-color;
  padding-bottom: $spacer;
  h3 {
    display:none
  }

  ul {
    display:flex;
    gap:$spacer $spacer*4;
    align-items: center;
    flex-wrap: wrap;
    @media (max-width:$bp-mobile-lg) {
      gap:$spacer*2;
    }

    @media (max-width:$bp-mobile-md) {
      display: grid;
      grid-template-columns: repeat(2,1fr);
      gap:$spacer;
    
    }

    @media (max-width:$bp-mobile-xs) {
      grid-template-columns: 1fr;
    }
    li {
      margin-bottom: 0;
      position:relative;
      
      &:not(:last-of-type)::before {
          content: "";
          width: 4px;
          height: 4px;
          background: $black;
          position: absolute;
          right: -$spacer*2;
          bottom: $spacer*0.75;
          border-radius: 100%
      }

      @media (max-width:$bp-mobile-lg) {
        &:not(:last-of-type)::before {
          display: none;
        }
      }

      @media (max-width:$bp-mobile-xs) {
        width: 100%;
      }
    }

    a {
      color: $black;
      @include transition-fast;
      &:hover {
      text-decoration: underline;
      color: $primary;}
    }
  }
}

.footer-container {
    padding-top: $spacer*2.5;
    padding-bottom:$spacer
}



.l-footer {
  background-color: $footer-bg;

  .cms-page-link,
  .account-list a {
    color: $text-muted;
    &:hover {
      color: $primary;
    }
  }
}

#footer {
  background:$off-white
}

.footer-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  > div {
    width:25%;
    @media (max-width:$bp-mobile-lg) {
      width: 50%;
    }

    @media (max-width:$bp-mobile-sm) {
      width: 100%;
    }
    > div {
      padding-top: $spacer*2
    }
  }

  li a {
    color:$black;
    @include transition-fast;
    
    &:hover {
      color:$primary;
    }
  }

  #block_myaccount_infos {
    border-radius: $border-radius-big;
    background:$white;
    @include card-box-shadow;
    max-width:$spacer*15;
    @media (max-width:$bp-mobile-lg) {
      max-width: unset;
    }
    margin-right:$spacer;
    padding:$spacer*2 $spacer*3.5 $spacer*2 $spacer*2.5;
  }
  .footer-link-block {
    width:50%;
    display:flex;
    @media (max-width:$bp-mobile-lg) {
      width:100%;
    }

    @media (max-width:$bp-mobile-sm) {
      flex-direction: column;
    }
    > div {
      flex:1;
      margin: 0 $spacer*2
    }

    li {
      position:relative;
      left: $spacer;
      &::after {
        content: "";
        background:$primary;
        width: 6px;
        height: 3px;
        position: absolute;
        left: -$spacer;
        bottom: $spacer*0.75;
      }
    }
  }

  .footer-logo {
      img {max-width:100%}
      svg {
        fill:$primary;
        max-width: $font-size-base*16;
      }

      @media (max-width:$bp-mobile-lg) {
       order:-1;
       >div{
        padding-left:$spacer;
        padding-right:$spacer;
       }
      }
      
    }
}

.footer-bar {
  background: $off-white;
  text-align: center;
  text-transform: uppercase;
  padding: $spacer*1.5;
  border-top: 1px solid $border-color;
  font-size: $font-size-base*0.75;
  a {
    font-weight: $weight-medium-bold;
  }
}


.link-block {
  li {
    margin-bottom: $spacer * 0.75;
  }
  a {
    @include transition-fast;
  }
}

.block-contact .footer-contact-block {
  > a {
    @include border-radius($border-radius-lg);
    font-weight: $weight-medium;
    background-color: $gray-100;
    padding: ($spacer * 0.5) $spacer;
    display: inline-block;
    color: $off-black !important;
    @include transition-fast;
    &:hover,
    &:active,
    &:focus {
      box-shadow: $box-shadow-sm;
      transform: translateY(-($spacer * 0.125));
      color: $green !important;
    }
  }
  gap: $spacer * 0.5;
  margin-bottom: $spacer;
}

.footer__title {
  color: $black;
  &:visited,
  &:hover {
    color: inherit;
  }
  display: block;
  text-transform: uppercase;
  font-weight: $weight-medium-bold;
  margin-bottom: $spacer*1.5;
  &--mobile {
    display: none;
    margin-bottom: $spacer;
    padding: $spacer/2 0;
    border-bottom: 1px solid $border-color;

    &:after {
      content: "\e313";
      font-family: "Material Icons";
      font-feature-settings: "liga" 1;
      position: absolute;
      right: $grid-gutter-width/2;
      @include transition(0.3s transform ease-in-out);
      font-size: $font-size-base * 1.5;
      font-weight: 100;
    }
    &[data-toggle="collapse"][aria-expanded="true"]:after {
      transform: rotate(180deg);
    }
  }
}

.footer__copyright {
  text-align: center;
  font-size: $font-size-sm;
}
.header__nav {
  align-items: center;
}
.header-nav__nav2 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.header-mobile__user-cart {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header__top {
  display: flex;
  justify-content: space-between;
  position: static;
}

@include media-breakpoint-mobile {
  .footer-row {
    // .footer-logo,
    // .link-block {
    //   flex-basis: 100%;
    // }
    // .block-contact {
    //   order: 1;
    // }
  }
  .footer__title {
    &--desktop {
      display: none;
    }
    &--mobile {
      display: block;
    }
  }

}


#index .footer-before:before {
  width: 50%;
  height: $spacer*48;
  bottom: -$narrowed;
}
.hero-wrapper {

  position:relative;
        .slider-featured-products {
        width: calc( 100% - ((100% - 1393px) / 2 ) );
        z-index: 9;
        bottom: 0;
        right: 0;
        position:absolute;
        padding: $spacer*3 $spacer*2.25 $spacer*0.25;
        border-top:$border-color solid 1px;
        background: transparentize($black, .4);
        @include blured;
        transform: translateY(10rem);
        opacity: 0;
        animation: fadeInRight .9s .35s forwards;
        @media (max-width:$bp-mobile-sm) {
          position: relative;
          
        }

        @media (max-width:$bp-desktop-md) {
          width: 100%;
          background: transparentize($black, .1);
          .product-miniature {
            width: 25%;
            @media (max-width:$bp-mobile-lg) {
              width: 50%;
            }

            @media (max-width:$bp-mobile-xs) {
              width: 100%;
            }
          }
        }

        .card {
          background:transparentize($white, .6);
          border-radius: $border-radius-lg;
      

        }

        h2 {
          font-size: $font-size-base*1.25;
          color: $white;
          text-transform: uppercase;
          margin-bottom:0
        }

        .card-body {
          flex-direction:row;
        }

        .card-product {
          flex-direction:row;
          min-height: $spacer*5;
        }

        .product__card-img {
          flex-basis:40%;
          border-radius: $border-radius-lg;
          display: flex;
	        align-items: center;
          background: $white
        }
        .product__card-desc {
          justify-content: center;
        }

        .product-title {flex-grow: inherit;}

        .product-title a, .product-title a:hover, .card-product:hover .product-title, .price{
          font-size: $spacer*.875;
          color: $white;
          font-weight: $weight-regular;
          line-height: 1.3
        }

        .price {
          font-weight: $weight-medium-bold;
        }

        .add-to-cart {
          display:none
        }

        .regular-price, .product-flags {
          display: none;
        }
      }
}
section.hero {
  // background: url(/themes/eneko-theme/assets/img/eneko-slider.jpg) center center;
  background: url(/themes/eneko-theme/assets/img/eneko-slider-panorama.jpg) right center;
  background-size: cover;
  color:$white;
  display: flex;
  position: relative;
  // @media(min-width:$bp-desktop-lg) {
  // animation: zoomBg 9s forwards;
  // }

  &::before {
    width: 100%;
    height: 100%;
    background: linear-gradient(64deg, $black, transparent);
    content: "";
    position: absolute;
    z-index: 0;
  }

  .hero-content-wrapper {
    z-index: 1;
    padding-top: $spacer*14;
    padding-bottom: $spacer*23;
    border-left:$border-color solid 1px;
    @media (max-width:$bp-mobile-lg) {
      padding-bottom: $spacer*40;
    }

    @media (max-width:$bp-mobile-sm) {
      padding-bottom: $spacer*3;
    }

  }

  h1 {
    font-size: $spacer *4.375;
    font-weight: $weight-medium-bold;
    transform: translateX(-10rem);
    opacity: 0;
    animation: fadeInLeft .8s .2s forwards;

    @media (max-width:$bp-mobile-lg) {
      font-size: $spacer *2.75;
    }
  }   
  

  p {
    font-size: $spacer *1.75;
    margin-bottom: $spacer *2.75;
    transform: translateX(-10rem);
    opacity: 0;
    animation: fadeInLeft .9s .35s forwards;
    @media (max-width:$bp-mobile-sm) {
      font-size: $spacer *1.25;
    }
  }

  .btn {
    font-size: $spacer*1.25;
    text-transform: none;
    padding: $spacer*1.5 $spacer*1.5 $spacer*1.5 $spacer*2.5;
    @include slider-btn-shadow;
    transform: translateY(5rem);
    opacity: 0;
    animation: fadeInBottom .9s .4s forwards;

    svg {
      fill: $white;
      color: $white;
      opacity: 1;
      margin-left: $spacer;
      margin-right: 0;
      max-width: $spacer*0.5;
    }
  }

  @keyframes fadeInLeft { 
    0% {opacity: 0;  transform: translateX(-10rem);}
    25% {opacity: 0;  transform: translateX(-5rem);}
    100% {opacity: 1; transform: translateX(0); }
  }

  @keyframes fadeInRight { 
    0% {opacity: 0;  transform: translateX(10rem);}
    25% {opacity: 0;  transform: translateX(5rem);}
    100% {opacity: 1; transform: translateX(0); }
  }

  @keyframes fadeInBottom { 
    0% {opacity: 0;  transform: translateY(5rem);}
    25% {opacity: 0;  transform: translateY(2.5rem);}
    90% {opacity: 1; transform: translateY(-0.75rem);}
    100% {opacity: 1; transform: translateY(0); }
  }
  @keyframes zoomBg { 
    0% {background-size: 100%;}
    100% {background-size: 110%;}
  }

  @keyframes fadeIn { 
    0% {opacity: 0;}
    100% {opacity: 1;}
  }

  .hero-contacts {
    color: $white;
    display: flex;
    gap: $spacer $spacer*5;
    flex-wrap: wrap;
    margin-top:$spacer*5;
    opacity:0;
    animation: fadeIn .9s 0.4s forwards;
    @media (max-width:$bp-mobile-sm) {
      gap: $spacer $spacer*3
    }
    a {
        color:$white;
        font-weight: $weight-medium;
        @include transition-fast;
        &:hover {
          text-shadow: 0px 1px 6px $white;
        }
      }

    svg {
        fill: white;
        width: 100%;
        max-width: $spacer;
        bottom: 2px;
        position: relative;
    }
  }
    
}

.hero-content-wrapper.container h1, .hero-content-wrapper.container p, .hero-contacts,  .slider-featured-products .section-title{
  @include tabbed;
}

.hero-content-wrapper.container .btn {
  left: $narrowed;
  @include media-breakpoint-down(sm) {
    left:0
  }
}

.slider-featured-products .section-title {
  margin-left: - $spacer*2.25;
}

.home-categories {
  background:$main-bg-img;
  .container.narrow {
    @include tabbed;

    p {
      max-width: $spacer*30;
      line-height: 1.6em;
    }

    
  }

  .section-title {
    position:relative;
    left:$narrowed*3;
    max-width:calc(100% - #{$narrowed*3});
    @include media-breakpoint-down(lg) {
      left:0;
      max-width: unset;
    }
    
  }

  .category-item {
    max-width: 20%;
    padding-left: $spacer/2;
    padding-right: $spacer/2;
    div.card {
      @include card-box-shadow;
      overflow:hidden;
      border-radius: $border-radius-lg;

      &:hover a {
        color:$white
      }
    }

    @include media-breakpoint-down(lg) {
      max-width: 33%;
    }

    @include media-breakpoint-down(md) {
      max-width: 50%;
    }

    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }
    .category-title, .category-title .btn {
        width: 100%;
        text-transform: none;
        font-weight: $weight-medium;
        color: $white;
    }
    .btn {
      padding: $spacer;
      @include btn-shadow;
        svg {
          fill: $white;
          opacity: 1;
          max-height: $font-size-base*.85;
          margin-left: $font-size-base*.25;
        }
    }
  }
}


.home-featured-products {
    background:$main-bg-img;
    .container.narrow {
      @include tabbed;
      @media (max-width:$bp-mobile-md) {
       padding-left: 0;
      }

    }
    @media (max-width:$bp-desktop-sm) {
      article:last-child {
       display: none;
      }
    }
    @media (max-width:$bp-mobile-md) {
      article:nth-child(4) {
       display: none;
      }
    }

    @media (max-width:$bp-mobile) {
      article:nth-child(4) {
       display: block;
      }
    }
    

    .section-title {
      margin-bottom: $spacer*4;
      position:relative;
      left:$narrowed*3;
      max-width:calc(100% - #{$narrowed*3});
      @media (max-width:$bp-mobile-md) {
        left:0;
        max-width:100%;
        margin-bottom: $spacer*1.5;
        
      }

      
    }

    

    
}


  .layout-home {
    // main > section:not(:first-of-type) {
    //     padding-top: $spacer*4;
    // }

    #content >section {
      padding-top: $section-spacer;
      padding-bottom: $section-spacer;
    }

    section#main {
        background-color: $white;
    }
    main {
        background-color: $off-white;
    }
      .view-more {
        a {
          font-size: $display5-size;
        }
        svg {
          width: $icon-large-size;
          }
      }

      .cta-section {
        background-color: white;
        h2 {
          font-size: $display2-size;
          font-weight: $weight-medium;
          margin-bottom: $spacer * .75;
        }
        .description {
          font-size: $h2-font-size;
          font-weight: $weight-regular;
          line-height: $big-line-height;
        }
        &.alternate .gradient {
          background: linear-gradient(90deg, rgba(241,243,246,1) 0%, rgba(252,243,241,1) 100%);
        }
      }
}



/* decorating lines */
@media (min-width:$bp-mobile-md) {
  @mixin decorating-lines {
    border: solid 1px $border-color;
    content: "";
    z-index: 0;
    position: absolute;
  }
  
  .home-categories>.container, .home-featured-products >.container{
    position: relative;
  }
  
  .home-categories > .container::after {
    @include decorating-lines;
    width: 46%;
    height: calc(100% + 4.9rem);
    top: 1.1rem;
    border-left: none;
    border-bottom: none;
    right: $narrowed;
  }
  
  .home-categories>.container:before {
    @include decorating-lines;
    width: 6rem;
    height: 7.2rem;
    top: -6rem;
    border-top: none;
    border-right: none;
    left: 4.74rem;
  }
  
  .home-featured-products>.container:after {
    @include decorating-lines;
    content: "";
    width: 52%;
    height: 7.2rem;
    top: -6rem;
    border-top: none;
    border-left: none;
    right: $narrowed;
  }
  
  .home-featured-products>.container:before {
    @include decorating-lines;
    width: 6rem;
    height: calc(100% + 4.9rem);
    top: 1.1rem;
    border-bottom: none;
    border-right: none;
    left: 4.74rem;
  }
  
  .home-categories>.container .row, .home-featured-products >.container .row, .product-accessories >.container .row {
    position:relative;
    z-index: 6;
  }
  
  .product-accessories  > .container::after {
    @include decorating-lines;
    width: 46%;
    height: calc(100% + 4.9rem);
    top: 1.1rem;
    border-left: none;
    border-bottom: none;
    right: $narrowed;
  }
  
}

/* Product miniature */
$product-miniature-bg-color: null;
$product-miniature-border-width: null;
$product-miniature-border-color: null;
$product-miniature-min-height:     36rem;

/* Product title */
$product-miniature-name-color:                $text-black;
$product-miniature-name-hover-color:          $primary;
$product-miniature-name-font-size:            $h3-font-size;
$product-miniature-name-font-weight:          $weight-medium;

$product-miniature-highlight-bg-color: $white;
$product-miniature-highlight-translateY: 0;


/* Product miniature*/
.product-miniature {
    width:20%;
    margin-bottom: $spacer * 2.5;
    @media (max-width:$bp-desktop-sm) {
        width: 25%;
    }

    @media (max-width:$bp-mobile-md) {
        width: 33.33%;
        margin-bottom: $spacer*1.5;
    }

    @media (max-width:$bp-mobile) {
        width: 50%;
    }

    @media (max-width:$bp-mobile-xs) {
        width: 100%;
    }

    .card {
        overflow:hidden;
        border-radius: $border-radius-lg;
        @include card-box-shadow;
        @include transition-fast;

        &:hover {
        box-shadow: $box-shadow-lift-up;
        transform: translateY(-0.25rem);}
    }
}



.product-miniature {
    padding-left:$spacer/2;
    padding-right:$spacer/2;

    .product-thumbnail img {
        padding: $spacer*2;
        background: $white;
    }


    .product-title {
        font-size:$font-size-base;
        font-weight: $weight-medium-bold;
        margin-bottom: $spacer/2;
    }
    
    .price {
        font-size:$font-size-base;
        font-weight: $weight-medium;
        color: $primary;
    }

    div.product-buttons {
        padding-bottom: $spacer/4;
        padding-top: $spacer;
    }

    .add-to-cart {
        width:100%;
        font-size: $font-size-base*0.875;
        color: $white;
        font-weight: $weight-medium;
        @include btn-shadow;

        svg {
            margin-right:$spacer/2;
            fill: $white;
            max-width: $spacer;
        }
    }

    .product-price-and-shipping {
        flex-direction: row;
        gap: 0.5rem;
        align-items: baseline;
    }

    .regular-price {
        font-size:$font-size-base*0.75
    }

    .current-price-discount {
        color: $primary;
    }



}
